%package,
.package {
  @extend %product--custom__container;
  position: relative;
}

%package__savings,
.package__savings {
  @extend %bg--ribbon;
  height: 107px;
  position: absolute;
  right: 0;
  top: -10px;
  width: 102px;
}

%package__savings-text,
.package__savings-text {
  display: block;
  padding: 15px 0 0 43px;
  text-align: center;
  transform: rotate(45deg);
}

%package__header,
.package__header {
  @extend %clearfix;
  margin: 0 -5px 20px -5px;
  width: 790px;
}

%package__title,
.package__title {
  @extend %h2--xxlarge-green;
  float: left;
  margin: 0 5px;
  width: 220px;
}

%package__price,
.package__price {
  @extend %h3--xlarge;
  float: left;
  margin: 0 5px;
  width: 150px;
}

%package__poses,
.package__poses {
  float: left;
  margin: 5px 5px 0 5px;
  width: 350px;
}

%package__description,
.package__description {
  @extend %content-helper--remove-both-child-margins;
  float: left;
  margin-top: 20px;
  width: 790px;
}

%package__grid,
.package__grid {
@extend %product--custom__grid;
}

%package__product,
.package__product {
  @extend %product--custom__product;
}

%package__actions,
.package__actions {
  @extend %product--custom__actions;
}

%package__btn-action--primary,
.package .btn-action--primary {
  @extend %product--custom__btn-action--primary;
}

%package__bonus,
.package__bonus,
.package__special-product {
  @extend %t--xlarge;
  float: left;
  width: 780px;
}

// PACKAGE SELECTOR
///////////////////
%package--selection,
.package--selection {

}

%package--selection__wrapper,
.package--selection__wrapper {
  @extend %clearfix;
  background-color: $grey-xlight;
  padding: 30px 40px;
}

%package--selection__option,
.package--selection__option {
  @extend %clearfix;
  border-bottom: 1px solid $grey-medium;
  margin: 15px 0;
  padding-bottom: 15px;
}

%package--selection__print-details,
.package--selection__print-details {
  float: left;
  margin-right: 10px;
  text-align: center;
  width: 150px;
}

%package--selection__print-details-item,
.package--selection__print-details .form-item {
  margin-bottom: 0;
}

%package--selection__print-image,
.package--selection__print-image {
  float: right;
  margin: 0 5px;
  width: 710px;
}

%package--seelction__print-image-radios,
.package--selection__print-image .form-radios {
  @extend %clearfix;
}

%package--selection__print-image-radio,
.package--selection__print-image .form-type-radio {
  position: relative;
  float: left;
  margin: 0 5px;
  height: 150px;
  width: 70px;
}

%package--selection__print-image-radio-input,
.package--selection__print-image input[type=radio] {
  border: 0;
  bottom: 0;
  left: 50%;
  margin: 10px 0 0 -6px;
  position: absolute;
}

%package--selection__print-image-radio-label,
.package--selection__print-image .form-type-radio label {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0;
  padding-bottom: 45px;
}

%package--selection__actions,
.package--selection__actions {
  @extend %clearfix;
  margin: 10px 0;

  .btn-action--link-cart {
    float: right;
  }
}

%package--selection__btn,
.package--selection__btn {
  @extend %btn-action--primary;
}

%package--selection__btn--back,
.package--selection__btn--back {
  @extend %btn-action--secondary-back;
  float: left;
  margin-right: 10px;
}

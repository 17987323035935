a {
  cursor: pointer;
  text-decoration: none;
  color: $grey-xdark;

  &:hover {
    text-decoration: underline;
    color: $black;
  }
}

%link--green {
  color: $purple;

  &:hover {
    color: $purple-dark;
  }
}

%link--large-green {
  @extend %link--green;
  @extend %t--large;
}

table {
  margin: 10px 0;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0 5px;
}

th {
  text-align: left;
}

th,
td {
  padding: 5px;
}
$max-width: 1040px;

%l-page--indent--core {
  margin-left: 40px;
}

%l-page__wrapper,
.l-page__wrapper {
  margin: 0 auto;
  width: $max-width;
}

%l-page__inner,
.l-page__inner {
  // padding: 0 25px 0 35px;
}

%l-page,
.l-page {
  background-color: $white;
  // padding: 0 12px;
  // width: 960px;
}

%l-page__header,
.l-page__header {
  @extend %clearfix;
}

%l-page__bottom,
.l-page__bottom {
  padding: 0 25px 50px 35px;
}

%l-page__main,
.l-page__main {
  @extend %clearfix;
  padding-bottom: 15px;
}

%l-page__sidebar-first,
.l-page__sidebar-first {
  float: left;
  margin-right: 5px;
  width: 245px;
}

%l-page__purchase-options,
.l-page__purchase-options {
  @extend %l-page--indent--core;
}

// No sidebars.
%l-page__content,
.l-page__content {
  // width: 100%;

  .page-print-shop-download & {
    @extend %l-page--indent--core;
  }
}

// Page with 1 sidebar last.
%l-page__content--has-sidebar-second,
.l-page__content.has-sidebar-second {
  float: left;
  margin-right: 5px;
  width: 785px;
}

// Page content with 2 sidebars.
%l-page__content--has-both-sidebars,
.l-page__content.has-sidebar-first.has-sidebar-second {
  margin-right: 5px;
  margin-left: 5px;
  width: 480px;
}

%l-page__sidebar-second,
.l-page__sidebar-second {
  float: left;
  margin-left: 5px;
  width: 295px;
}

// Order pages.
// @uri user/%uid/orders/%order_id
%l-page__content--grey,
.page-user-orders .l-page__content {
  background-color: $grey-xlight;
  padding: 10px;
}

// Checkout page overrides
.page-checkout .l-page__content {
  margin: 0 auto;
  width: 600px;
}

// Print selections/checkout page overrides
.page-print-selections .l-page__content.has-sidebar-second,
.page-print-checkout .l-page__content.has-sidebar-second {
  width: 550px;
}

.page-print-selections .l-page__sidebar-second,
.page-print-checkout .l-page__sidebar-second {
  width: 400px;
}

// %print-viewer,
// .print-viewer {

// }

%print-viewer__steps,
.print-viewer__steps {
  @extend %clearfix;
  display: block;
  position: relative;
  margin: 0 0 10px;
  width: 100%;
  height: 30px;

  &:after {
    @extend %icon--arrow-left-light-purple;
    position: absolute;
    top: 0;
    left: 50%;
    width: 15px;
    height: 30px;
    content: '';

    .page-print-checkout & {
      @extend %icon--arrow-right-xlight-purple;
    }
  }
}

%print-viewer__step,
.print-viewer__step {
  @extend %t--large;
  display: block;
  float: left;
  background-color: $purple-xlight;
  width: 50%;
  height: 30px;
  vertical-align: middle;
  text-indent: 30px;
  line-height: 30px;
  color: $purple;

  &:last-child {
    text-indent: 45px;
  }
}

.print-viewer__table {
  table-layout: fixed;
}

%print-viewer__step--active--core {
  background-color: $purple;
  color: $white;
}

%print-viewer__step-one--active,
.page-print-selections .print-viewer__step:first-child {
  @extend %print-viewer__step--active--core;
}

%print-viewer__step-two--active,
.page-print-checkout .print-viewer__step:last-child {
  @extend %print-viewer__step--active--core;
}

%print-viewer__actions,
.print-viewer__actions {
  @extend %clearfix;
  margin: 5px 0;
}

%print-viewer--checkout__actions,
.print-viewer--checkout__actions {
  @extend %print-viewer__actions;
  margin-top: 15px;

  .btn-action--link-forward {
    float: right !important;
  }
}

%print-viewer__btn-action,
.print-viewer__actions a {
  float: right;
  margin-right: 5px;

  &:first-child {
    margin-right: 0;
  }
}

%print-viewer--checkout__btn-action,
.print-viewer--checkout__actions a,
.print-viewer--checkout__actions input[type=submit] {
  float: left;
  margin: 0 5px 0 0;
}

%print-viewer__grid,
.print-viewer__grid {
  margin: 5px 0;
  background-color: $grey-xlight;
  padding: 20px 40px;
}

%print-viewer__grid--selection,
.print-viewer__grid--selection {
  @extend %print-viewer__grid;
}

%print-viewer__subcategories,
.print-viewer__subcategories {

  > :last-child {
    margin-bottom: 0;
  }
}

%print-viewer__subcategory,
.print-viewer__subcategory {
  margin-bottom: 30px;
}

%print-viewer__subcategory-title,
.print-viewer__subcategory > h3 {
  margin-bottom: 5px;
  font-weight: $bold-font-weight;
}

%print-viewer__seperator,
.print-viewer__seperator {
  margin: 30px 0;
}

%print-viewer__table,
.print-viewer table,
.print-viewer--checkout table {
  margin: 0 -5px;
  width: auto;
}

%print-viewer--selection__table,
.print-viewer__grid--selection table {
  margin: 0 auto;
}

%print-viewer__td,
.print-viewer td,
.print-viewer--checkout td {
  padding: 5px;
  text-align: center;
}

%print-viewer__print,
.print-viewer__print {
  @extend %icon--loader;
  display: table-cell;
  background-position: center 30px;
  width: 105px;
  height: 105px;
  vertical-align: bottom;
  text-align: center;
}

%print-viewer__print-link,
.print-viewer__print a {
  display: inline-block;
  vertical-align: top;

  &.selected,
  .print-viewer--is-disabled & {
    opacity: 0.5;
  }

  .print-viewer--is-disabled & {
    cursor: no-drop;
    pointer-events: none;
  }
}

%print-viewer__print--is-disabled,
.print-viewer--is-disabled .print-viewer__print,
.print-viewer__print.is-picked {
  background-image: none;
}

%print-viewer__print-image,
.print-viewer__print img {
  display: block;
}

%print-viewer__print-image--green-screen,
.print-viewer__print.is-green-screen img {
  background-color: $green-dark;
}

%print-viewer__title,
.print-viewer__title {
  @extend %t--xxsmall;
  border-top: 1px solid $grey-dark;
  padding-top: 10px;
  text-align: center;
  font-family: $alternate-font;
  word-wrap: break-word;
  width: 105px;
  margin: 10px 0 0;
}

%print-viewer__label,
.print-viewer label {
  display: inline;
  margin: 0 5px 0 25px;
}

%print-viewer__btn-action--parimary,
.print-viewer .form-submit {
  padding: 0;
  width: 100%;
}

// PRINT VIEWER CHECKOUT - PRINT CHECKOUT PAGE
//////////////////////////////////////////////
// %print-viewer--checkout,
// .print-viewer--checkout {

// }

%print-viewer--checkout__qty,
.print-viewer--checkout .form-item-qty {
  text-align: center;
}

%print-viewer--checkout__qty-label,
.print-viewer--checkout .form-item-qty label {
  display: inline;
  margin-right: 5px;
}

%print-viewer--checkout__qty-input,
.print-viewer--checkout .form-item-qty input {
  padding: 4px;
  width: 30px;
  text-align: center;
}

%print-viewer--checkout__submit,
.print-viewer--checkout input[type=submit] {
  width: 100%;
}

%image-preview,
.image-preview {
  @extend %clearfix;
}

%image-preview--backgrounds,
.image-preview.has-backgrounds {
  width: 615px;
}

%js-image-preview__mesage,
.js-image-preview__message {
  @extend %t--xlarge;
  margin-top: 40%;
}

%image-preview__preview,
.image-preview .image-add {
  float: left;

  form {
    position: absolute;
    bottom: -2px;
    z-index: 9999;
    margin-bottom: 0;
    width: 345px;
  }
}

.image-preview.has-backgrounds .image-add form {
  width: 380px;
}

%image-preview--backgrounds__preview,
.image-preview.has-backgrounds .image-add {
  margin-right: 5px;
}

%image-preview--is-loading__print,
.image-preview.image-preview--is-loading .image,
.image-preview.has-backgrounds.image-preview--is-loading .image {
  @extend %icon--loader;
  background-color: $white;

  img {
    opacity: 0;
  }
}

%image-preview--backgrounds__print,
.image-preview.has-backgrounds .image {
  margin-top: 0;
  background: $green-dark;
}

%image-preview__print-img,
.image-preview .image img {
  display: block;
}

// Background Selection Tool of Image Preview
%image-preview__background-title,
.image-preview__background-title {
  margin: 5px 5px 10px;
  text-align: center;
}

%image-preview__background-category-label,
.image-preview__background-category-label {
  color: $black;
  margin: 5px;
}

%image-preview__background-category-description,
.image-preview__background-category-description {
  @extend %t--red-italic;
  margin: 5px;
}

%image-preview__background-selections,
.image-preview .background-selections {
  background-color: $grey-xlight;
  float: left;
  height: 570px;
  margin-left: 5px;
  overflow-y: scroll;
  padding: 0 5px;
  width: 200px;

  .page-print-viewer & {
    height: 570px;
  }
}

%image-preview__backgrounds,
.image-preview .backgrounds {
  @extend %clearfix;
  margin: 0 auto;
  padding-bottom: 35px;
}

%image-preview__background-link,
.image-preview .backgrounds a {

}

%image-preview--is-loading__background-link,
.image-preview--is-loading .backgrounds a {
  opacity: 0.5;
  cursor: no-drop;
}

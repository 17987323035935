// Viewing an order entity.
// %order--full,
// .entity-commerce-order {

// }

%order--full__coupons,
.entity-commerce-order .field-name-commerce-coupons {
  display: none;
}

%order--full__label,
.entity-commerce-order .field-label {
  color: $black;
}
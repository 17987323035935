%btn-primary--core {
  @extend %t--medium;
  display: inline-block;
  border: 0;
  padding: 6px;
  text-align: center;
  font-weight: $base-font-weight;

  &:hover {
    text-decoration: none;
  }
}

%btn-primary--small--core {
  min-width: 80px;
}

%btn-primary--large--core {
  min-width: 222px !important;
}

%btn-primary--purple--core {
  background-color: $purple;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    background-color: $purple-dark;
    color: $white;
  }
}

%btn-primary--blue--core {
  background-color: $blue;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    background-color: $blue-dark;
    color: $white;
  }
}

%btn-primary--purple,
.btn-primary--purple {
  @extend %btn-primary--core;
  @extend %btn-primary--small--core;
  @extend %btn-primary--purple--core;
}

%btn-primary--purple-large,
.btn-primary--purple-large {
  @extend %btn-primary--core;
  @extend %btn-primary--purple--core;
  @extend %btn-primary--large--core;
}

%btn-primary--blue-large,
.btn-primary--blue-large {
  @extend %btn-primary--core;
  @extend %btn-primary--blue--core;
  @extend %btn-primary--large--core;
}

input.btn-primary--blue-large {
  @extend %btn-primary--blue-large;

  &:hover,
  &:focus,
  &:active {
    background-color: $blue-dark !important;
  }

  &.js-unselect {
    opacity: 0.45;

    &:hover,
    &:focus,
    &:active {
      opacity: 1;
    }
  }
}

%btn-primary--full--core {
  width: 100%;
}

%btn-primary--full {
  @extend %btn-primary--core;
  @extend %btn-primary--full--core;
}

%btn-primary--full-purple,
.btn-primary--full-purple {
  @extend %btn-primary--core;
  @extend %btn-primary--purple--core;
  @extend %btn-primary--full--core;
}

//------------------------------------------------//







.page-user-orders .l-page__content {
  position: relative;
  margin-bottom: 50px;

  .print-button {
    @extend %btn-primary--full-purple;
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 200px;
  }
}






%btn-action--core {
  display: inline-block;
  border: 0;
  padding: 0 5px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  color: $white;
  font-size: 14px;

  &:hover,
  &:active {
    color: $white;
  }
}

%btn-action--arrow--core {
  display: block;
  position: absolute;
  top: 0;
  background-color: $white;
  width: 15px;
  height: 30px;
  content: '';
}

%btn-action--primary,
.btn-action--primary {
  @extend %btn-action--core;
  background-color: $purple;
}

%btn-action--primary-forward,
.btn-action--primary-forward {
  @extend %btn-action--primary;
  position: relative;
  padding-right: 20px;

  &:after {
    @extend %btn-action--arrow--core;
    @extend %icon--arrow-right-purple;
    right: 0;
  }
}

input[type=submit].btn-action--primary-forward:focus {
  background-color: $purple;
}

// Used a a wrapper for elements that cannot use the :after/:before like inputs.
%btn-action--primary-forward__container,
.btn-action--primary-forward__container {
  display: inline-block;
  position: relative;
  margin: 5px 0;
  padding-right: 15px;


  &:after {
    @extend %btn-action--arrow--core;
    @extend %icon--arrow-right-purple;
    right: 0;
  }

  input[type=submit] {
    margin: 0;

    &:hover {
      background-color: $green !important;
      text-decoration: underline;
    }
  }
}

%btn-action--primary-forward-grey__container,
.btn-action--primary-forward-grey__container {
  @extend %btn-action--primary-forward__container;
  @extend %btn-action--primary-forward-grey;
}

%btn-action--primary-forward-grey,
.btn-action--primary-forward-grey {
  @extend %btn-action--primary-forward;

  &:after {
    background-color: $grey-xlight;
  }
}

%btn-action--secondary,
.btn-action--secondary {
  @extend %btn-action--core;
  background-color: $purple-light;
}

%btn-action--secondary-block,
.btn-action--secondary-block {
  @extend %btn-action--secondary;
  display: block;
}

%btn-action--secondary-back,
.btn-action--secondary-back {
  @extend %btn-action--secondary;
  position: relative;
  padding-left: 20px;

  &:before {
    @extend %btn-action--arrow--core;
    @extend %icon--arrow-left-light-purple;
    left: 0;
  }
}

%btn-action--secondary-back-grey,
.btn-action--secondary-back-grey {
  @extend %btn-action--secondary-back;

  &:before {
    background-color: $grey-xlight;
  }
}

/**
 * Secondary buttons.
 */
%btn-secondary {
  @extend %t--large;
  display: block;
  padding: 10px 5px;
  text-align: center;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

// Grey variation.
%btn-secondary--grey,
.btn-secondary--grey {
  @extend %btn-secondary;
  background-color: $grey-medium;
  color: $black;

  &:hover,
  &:focus,
  &:active {
    background-color: $grey-dark;
    color: $white;
  }
}

// Green variation.
%btn-secondary--green,
.btn-secondary--green {
  @extend %btn-secondary;
  background-color: $purple;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    background-color: $purple-dark;
    color: $white;
  }
}

// Blue Primary

%btn--primary-blue,
.btn--primary-blue {
  background-color: $blue !important;
  padding: 10px 10px !important;
  min-width: 222px !important;

  &:hover,
  &:focus,
  &:active {
    background-color: $blue-dark !important;
  }
}

// Button Link - Text with icons

%btn-action--link {
  background-color: transparent !important;
  background-position: right center;
  background-repeat: no-repeat;
  color: $purple-dark !important;
  cursor: pointer;
  padding: 3px 25px 3px 0 !important;

  &:hover,
  &:focus,
  &:active {
    color: $purple !important;
    text-decoration: none !important;
  }
}

.btn-action--link-cart {
  @extend %btn-action--link;
  @extend %icon--cart;

  &:hover,
  &:active,
  &:focus {
    background-image: url('./img/ico-cart-hover.png');
  }
}

.btn-action--link-update {
  @extend %btn-action--link;
  @extend %icon--update;
}

.btn-action--link-forward {
  @extend %btn-action--link;
  @extend %icon--arrow-right;
}

.btn-action--link-backward {
  @extend %btn-action--link;
  @extend %icon--arrow-left;

  background-position: left center;
  padding: 3px 0 3px 25px !important;
}

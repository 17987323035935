%print,
.print {
  @extend %product--custom__container;
}

%print__title,
.print__title {
  @extend %h2--xxlarge-green;
}

%print__grid,
.print__grid {
  @extend %product--custom__grid;
}

%print__product,
.print__product {
  @extend %product--custom__product;
}

%print__actions,
.print__actions {
  @extend %product--custom__actions;
}

%print__btn-action--primary,
.print .btn-action--primary {
  @extend %product--custom__btn-action--primary;
}

//------------------------------------------------//
// %c-print,
// .c-print {

// }

// %c-print__image,
// .c-print__image {

// }

%c-print__image--selected,
.c-print.is-selected .c-print__image {
  opacity: 0.45;
}

.c-print__pose {
  @extend %t--xxsmall;
  @extend %t--align-center;
  min-height: 36px;
}

.c-print.is-selected input[type="submit"] {
  background-color: $purple-dark;
}

@mixin visually-hidden {
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);

  &.focusable {
    background-color: palette(grey, xlight);
    padding: ru(0.5);
    color: palette(blue, dark);

    &:active,
    &:focus {
      position: static;
      margin: 0;
      width: auto;
      height: auto;
      overflow: visible;
      text-decoration: underline;
      clip: auto;
    }
  }
}

%u-visually--hidden,
.u-visually--hidden {
  @include visually-hidden;
}

%l-eq,
.l-eq {
  @extend %clearfix;
}

%l-eq__item,
.l-eq__item {
  box-sizing: border-box;
}

// Gutters.
.l-eq--gutters-default {
  margin-right: -5px;
  margin-left: -5px;

  > .l-eq__item {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.l-eq--gutters-large {
  margin-right: -10px;
  margin-left: -10px;

  > .l-eq__item {
    padding-right: 10px;
    padding-left: 10px;
  }
}

%l-eq--3col,
.l-eq[data-eq-state="3col"] {
  @extend %l-eq;

  .l-eq__item {
    float: left;
    width: (1 / 3) * 100%;

    &:nth-child(3n+1) {
      clear: both;
    }
  }
}

%l-eq--8col,
.l-eq[data-eq-state="8col"] {
  @extend %l-eq;

  .l-eq__item {
    float: left;
    margin-bottom: 10px;
    width: (1 / 8) * 100%;

    &:nth-child(8n+1) {
      clear: both;
    }
  }
}

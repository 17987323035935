%l-3col,
.view-store-front .view-content,
.view-taxonomy-term .view-content,
.view-gift-registry .view-content {
  @extend %clearfix;
  margin: -5px;
}

%l-3col__item,
.view-store-front .views-row,
.view-taxonomy-term .views-row,
.view-gift-registry .views-row {
  float: left;
  margin: 5px;
  width: 153px;

  &:nth-child(3n+1) {
    clear: both;
  }
}

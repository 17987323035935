// PRODUCT FULL
///////////////
// Full generic product display.
// %product--full,
// .product--full {

// }

%product--full__header,
.product--full__header {
  @extend %clearfix;
  margin-bottom: 20px;
  border-bottom: 1px solid $grey-medium;
}

%product--full__title,
.product--full__title {
  @extend %h2--xlarge-green;
  box-sizing: border-box;
  float: left;
  margin: 20px 0;
  padding-right: 5px;
  width: 80%;
}

// %product--full__variation,
// .product--full select {

// }

%product--full__price,
.product--full__price {
  @extend %h2--xlarge-green;
  box-sizing: border-box;
  float: right;
  font-family: $title-font;
  margin: 20px 0;
  padding-left: 5px;
  text-align: right;
  width: 20%;
}

%product--full__form-wrapper,
.product--full form > div {
  @extend %clearfix;
}

%product--full__quantity-container,
.product--full .form-item-quantity {
  float: left;
  margin: 10px 10px 10px 0;
}

.product--full.is-composite .form-item-quantity {
  display: none;
}

%product--full__quantity-label,
.product--full .form-item-quantity label {
  display: inline;
  margin-right: 5px;
}

%product--full__quantity-input,
.product--full .form-item-quantity input {
  padding: 4px;
  text-align: center;
  vertical-align: middle;
  width: 30px;
}

%product--full__submit,
.product--full input[type=submit] {
  margin: 10px 0;
  width: 140px;
}

%product--full__category,
.product--full__category {
  border-top: 1px solid $grey-medium;
  margin: 20px 0;
  padding-top: 20px;
  text-align: right;
}

%product--full__category-link,
.product--full__category a {
  @extend %link--large-green;
}

.product--full.is-composite-photo-upload .form-item {
  margin: 20px 0;
}

.product--full.is-composite-photo-upload label {
  font-size: 18px;
}

.product--full.is-composite-photo-upload .description {
  font-size: 14px;
}

// Product Teaser
///////
.product--teaser {
  background-color: $grey-light;
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.product--teaser__img-wrapper {

  a {
    display: block;
  }

  img {
    display: block;
    margin: 0 auto;
  }
}

.product--teaser__content {
  margin-bottom: 5px;
  min-height: 40px;
}

.product--teaser__label {
  font-size: 13px;
  font-weight: $bold-font-weight;
  line-height: 17px;
  margin: 10px 5px 5px 5px;
}

.product--teaser__description {
  @extend %content-helper--remove-both-child-margins;

  font-size: 13px;
  line-height: 17px;
  margin: 5px;
}

%product--teaser__btn,
.product--teaser__btn {
  @extend %btn-action--primary;

  display: block;
  margin-top: auto;
}

// PRODUCT CUSTOM
/////////////////
// Used for print and package products.
%product--custom__container {
  @extend %clearfix;
  background-color: $grey-xlight;
  margin-bottom: 10px;
  padding: 15px 110px 15px 35px;
}

%product--custom__grid {
  float: left;
  margin: 20px 0;
  width: 590px;
  min-height: 140px;
}

%product--custom__product {
  border-left: 1px solid $white;
  border-right: 1px solid $grey-medium;
  float: left;
  margin-bottom: 10px;
  min-height: 140px;
  padding-top: 10px;
  text-align: center;
  width: 100px;

  &:nth-child(5n+1) {
    border-left: 0;
    clear: both;
  }

  &:nth-child(5n+5) {
    border-right: 0;
  }
}

%product--custom__actions {
  float: left;
  margin-top: 70px;
  width: 190px;
}

%product--custom__btn-action--primary {
  display: block;
}
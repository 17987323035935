// %l-shop,
// .l-shop {

// }

%l-shop__header,
.l-shop__header {
  @extend %clearfix;
}

%l-shop__message,
.l-shop__message {
  float: left;
  padding-right: 5%;
  width: 65%;
}

%l-shop__price-list,
.l-shop__price-list {
  float: right;
  width: 30%;

  .label {
    display: inline-block;
    width: 175px;
  }
}

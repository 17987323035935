%pager,
.pager {
  text-align: right;
}

%pager__item,
.pager li {
  display: inline-block;
  border: 1px solid $grey-medium;
  background-color: $grey-xlight;
  list-style-type: none;
  padding: 5px 10px;
}

%pager__item--active,
.pager li.pager-current {
  background-color: $grey-medium;
}

// On checkout page.
%coupon-listing,
.view-order-coupon-list {
  clear: both;
}

// @todo table styling.
%coupon-listing__cell,
.view-order-coupon-list td {
  border-top: 1px solid $grey-medium;
  background-color: $grey-light;
}

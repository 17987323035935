/**
 * Logo
 ******/
 .logo {
  display: inline-block;
  margin-left: 38px;

  img {
    vertical-align: bottom;
  }
}

.security-provider {
  margin: 5px 0 0 0;
}

.security-provider a {
  float: right;
}

.bold {
  color: $black;
}

.page-print-checkout .print-options {
  background-color: #F2F2F3;
  margin-top: 5px;
  padding: 30px 40px 0;
}

.page-print-checkout .image-selection .style {
  margin-top: 15px;
  text-align: center;
}

.page-print-checkout .image-selection .style .image {
  height: 70px;
}

.page-print-checkout .actions {
  padding: 15px 0 0 0;
}

.page-print-checkout-add-individual .block-system-main .individual-prints {
  background: #F2F2F3;
  padding: 15px 35px;
}

.page-print-checkout-add-individual .block-system-main tr {
  border-bottom: 1px solid $grey;
}

.page-print-checkout-add-individual .block-system-main td {
  padding: 15px 5px 15px;
  text-align: center;
}

.page-print-checkout-add-individual .block-system-main select {
  width: 100%;
}

.page-print-checkout-add-individual .block-system-main .return {
  margin-right: 5px;
}

.page-cart .main .content {
  background-color: #F2F2F3;
  padding: 30px 40px;
}
/**
 * COMMERCE CART FORM
 ********************/

.view-commerce-cart-form .views-field-commerce-total {
  width: 100px;
}

.view-commerce-cart-form td.views-field-commerce-total {
  text-align: right;
}

.view-commerce-cart-form .commerce-line-item-actions a,
.view-commerce-cart-form .commerce-line-item-actions input {
  float: left;
}

/**
 * Checkout Page
 *
 */
.page-checkout .block-system .content {
  margin: 0 auto;
  width: 470px;
}

.page-print-checkout .block-system-main .actions .add {
  margin-left: 5px;
}

.page-checkout-complete .block-system .content {
  width: 100%;
  margin: 0 40px;
}

#edit-cart-contents legend {
  display: none;
}


.page-checkout .view-commerce-coupon-review-pane {
  display: block;
  float: left;
  width: 100%;
}



.page-checkout .view-commerce-coupon-review-pane .image {
  float: left;
  margin-right: 5px;
}

.page-checkout .view-commerce-cart-summary table.commerce-price-formatted-components {
  clear: both;
  float: right;
  width: 75%;
}

/**
 * COMMERCE CART SUMMARY VIEW *
 ******************************/


.page-checkout .checkout-buttons input {
  margin: 0;
}

.page-checkout .checkout-buttons #edit-back {
  background-color: $purple-light;
}

.page-checkout .checkout-buttons #edit-continue {
  float: right;
}

.page-checkout .checkout-buttons {
  border: 0;
  margin-bottom: 0;
}

.page-checkout  .cart_contents {
  border-bottom: 1px solid $purple-xxlight;
  padding: 0 0 20px 0;
}

fieldset.customer_profile_billing {
  position: relative;
}

.customer_profile_billing .form-wrapper,
.condo-apt-details,
.shipping-warning {
  float: left;
  width: 320px;
}

.customer_profile_billing .field-name-field-shipping-instructions {
  float: right;
  width: 270px;
}

.condo-apt-details {
  border-top: 1px solid #CCC;
  margin: 10px 0 0;
  padding: 10px 0 0;
}
.condo-apt-details h2 {
  color: $purple;
  font-family: adobe-garamond-pro, Garramond, serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: $base-font-weight;
}

.condo-apt-details .description {
  @extend %clearfix;
  margin: 5px 0 0;
}

#customer-profile-billing-field-shipping-instructions-add-more-wrapper label {
  width: 200px;
}

#edit-commerce-shipping-shipping-method-pickup-pickup {
  text-align: right;
  margin-top: 7%;
}


#edit-commerce-shipping-shipping-method-schools .form-item-commerce-shipping-shipping-method-schools-schools,
#edit-commerce-shipping-shipping-method-schools .form-item-commerce-shipping-shipping-method-schools-school-agreement {
  float: left;
  margin: 0 0 7px;
}

#edit-commerce-shipping-shipping-method-schools .form-item-commerce-shipping-shipping-method-schools-school-agreement {
  border: 1px solid #ccc;
  padding: 2px;
  margin-bottom: 0;
}

#edit-commerce-shipping-shipping-method-schools-school-agreement {
  float: left;
}

.commerce_shipping .form-wrapper .form-type-radios {
  height: 44px;
}

.commerce_shipping a.directions {
  font-size: 14px;
  line-height: 18px;
  float: right;
}

.page-checkout .checkout-buttons #edit-cancel {
  background-color: $purple-light;
  display: none;
}

/**
 * Commerce Review Order Page
 *
 */
.page-checkout-review .checkout-help {
  background: transparent url('../images/icon--info.png') no-repeat 0 5px;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 0 15px;
  min-height: 35px;
  padding: 5px 5px 5px 40px;
}

.checkout-review .pane-title {
  display: none;
}

.page-checkout-review .view-commerce-shipping-summary {
  background: $purple-xxlight;
  padding: 15px;
}

table.checkout-review .pane-data-full {
  padding: 0;
}

.page-checkout-review .checkout-review,
.page-checkout-review .view-commerce-shipping-summary table {
  width: 100%;
}

.page-checkout-review .view-commerce-shipping-summary .view-content {
  border-bottom: 1px solid $white;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.page-checkout-review .field-name-commerce-customer-address {
  margin: 15px 0 0 0;
}

.page-checkout-review  .field-name-field-phone,
.page-checkout-review  .field-name-field-email,
.page-checkout-review  .field-name-field-shipping-instructions,
.page-checkout-review  .field-name-field-special-instructions {
  margin: 5px 0 0 0;
}

.page-checkout-review .field-name-field-passcode {
  display: none;
}

.page-checkout-review #edit-coupon {
  border-bottom: 0;
  border-top: 1px solid $purple-xlight;
  margin: 20px 0;
  padding: 20px 0 0;
}

.page-checkout-review #edit-coupon h2 {
  color: $purple;
  font-size: 20px;
  font-weight: $base-font-weight;
  line-height: 24px;
}

.page-checkout .view-commerce-coupon-review-pane table,
.page-checkout-review #edit-coupon table {
  width: 100%;
}

.page-checkout .view-commerce-coupon-review-pane th,
.page-checkout-review #edit-coupon th {
  border-bottom: 1px solid $purple-light;
  padding: 5px 15px 5px 10px;
}

.page-checkout .view-commerce-coupon-review-pane td,
.page-checkout-review #edit-coupon td {
  background-color: $grey-xlight;
  font-weight: $base-font-weight;
  padding: 5px 15px 5px 10px;
  vertical-align: middle;
}

.page-checkout .view-commerce-coupon-review-pane td .empty,
.page-checkout-review #edit-coupon td .empty {
  text-align: center;
}

.page-checkout-review .commerce-payment-pane {
  border-top: 1px solid $purple-xlight;
  margin-top: 20px;

}

.page-checkout .checkout-completion-message a {
  text-decoration: underline;
}


/**
 * Orders
 */
.page-user-orders .l-page__content .region-content {
  margin: 30px 40px;
}

.page-user-orders .field-name-commerce-customer-billing .field  {
  margin: 5px 0;
}

.page-user-orders .field-name-commerce-customer-billing .field-name-commerce-customer-address {
  margin-top: 0;
}

.page-user-orders .field-name-field-passcode {
  display: none;
}

/**
 * Payment Redirect Form
 *
 */
.page-checkout-payment .block-system-main .form-submit {
  margin: 10px 0;
}

/**
 * Front Page & Product Taxonomy pages
 *
 */
// .view-store-front .node,
// .view-taxonomy-term .node,
// .view-gift-registry .node {
//   background-color: #F2F2F3;
//   padding: 5px;
// }

// .view-store-front h4,
// .view-taxonomy-term h4,
// .view-gift-registry h4 {
//   font-size: 13px;
//   font-weight: $bold-font-weight;
//   height: 35px;
//   line-height: 17px;
//   margin-top: 5px;
//   overflow: hidden;
//   padding: 0 10px;
// }

// .view-store-front .description,
// .view-taxonomy-term .description,
// .view-gift-registry .description {
//   font-size: 13px;
//   height: 95px;
//   line-height: 17px;
//   overflow: hidden;
//   padding: 0 10px;
// }

// .view-store-front .description p,
// .view-taxonomy-term .description p,
// .view-gift-registry .description p {
//   margin: 0;
// }

// .view-store-front .price,
// .view-taxonomy-term .price,
// .view-gift-registry .price {
//   color: #6E8B40;
//   text-align: center;
// }

// .view-store-front .button,
// .view-taxonomy-term .button,
// .view-gift-registry .button {
//   text-align: center;
//   width: 100%;
// }

// .view-store-front .button a,
// .view-taxonomy-term .button a,
// .view-gift-registry a {
//   margin: 0;
//   padding: 0;
// }

.view-past-composites .view-filters {
  margin: 15px 0;
}

/**
  * Colorbox Message
 */
// #cboxLoadedContent .message {
//   height: 200px;
// }

#cboxLoadedContent .message .info {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
}

#cboxLoadedContent .message .buttons {
  text-align: center;
  margin: 0;
}

#cboxLoadedContent .message .buttons a {
  display: inline-block;
  background: $purple-light;
  color: $white;
  font-size: 24px;
  line-height:30px;
  margin-right: 30px;
  padding: 10px 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  min-width: 200px;

  &:hover,
  &:focus,
  &:active {
    background-color: $purple-dark;
    text-decoration: none;
  }
}

#cboxLoadedContent .background-display h2 {
  text-align: center;
}

#cboxTitle {
  background: $white;
  display: block !important;
  height: auto !important;
  max-height: 30px;
  padding: 4px 0 0 !important;
}

.page-print-selections #cboxTitle {
  display: none !important;
}

/** MAINTENANCE SCREEN **/
.maintenance-page #page {
  background: $white;
  height: 100%;
}

.maintenance-page .l-page__content {
  padding: 50px 20px;
}

.filter-form {
  clear: both;
  padding: 1em 0;
}

#nps-print-viewer-form {
  border: 1px solid #a9a9a9;
  padding: 15px;
}

#nps-print-viewer-form  label,
#nps-print-viewer-form  select,
#nps-print-viewer-form .form-item,
#nps-print-viewer-form  input {
  display: inline-block;
}

#nps-print-viewer-form  label {
  margin-right: 5px;
}

#nps-print-viewer-form .more-info {
  color: rgb(224, 86, 90);
  display: inline;
  margin-left: 15px;
}

.page-print-selections-package #nps-print-viewer-form .more-info {
  display: block;
  margin: 1em 0 0;
}

/** Gift Registry Override **/
.gift-registry.node-product-display .category {
  display: none;
}


/**
 * Basic Page Node
 *
 */
.node-type-page .block-system-main {
  margin:0 35px;
}

.clearfix {
  @extend %clearfix;
}

%c-card,
.c-card {
  background-color: $grey-xlight;
  text-align: center;
}

%c-card__title,
.c-card__title {
  @extend %t--large;
  margin-bottom: 15px;
  padding-top: 15px;
  color: $grey-xdark;
  font-weight: $title-font-weight;
}

// %c-card__label,
// .c-card__label {

// }

%c-card__price,
.c-card__price {
  @extend %t--huge;
  @extend %t--xdark-grey;
  margin-bottom: 15px;
}

%c-card__btn,
.c-card__btn {
  @extend %btn-secondary--green;
}

%print-selector--cart,
.print-selector--cart {
  background-color: $grey-xlight;
  padding: 30px 20px;

  .btn-action--link-backward {
    float: left;
  }

  .btn-action--link-forward {
    float: right;
  }
}

%print-selector--cart-top,
.print-selector--cart.top {
  position: relative;
}

%print-selector--cart-bottom,
.print-selector--cart.bottom {

  .page-print-selections-package & {
    padding-top: 0;
  }
}

%print-selector--cart__title,
.print-selector--cart > h2 {
  @extend %h2--xlarge-green;
}

%print-selector--cart__price,
.print-selector--cart .price {
  font-style: italic;
  font-weight: $base-font-weight;
}

%print-selector--cart__savings,
.print-selector--cart .savings {
  @extend %package__savings;
}

%print-selector--cart__savings-text,
.print-selector--cart .savings .text {
  @extend %package__savings-text;
}

%print-selector--cart__print-info,
.print-selector--cart .print-info {
  @extend %clearfix;
  border-bottom: 1px solid $purple;
  border-top: 1px solid $purple;
  margin: 15px 0;
  padding: 15px 0;
}

%print-selector--cart__print-details,
.print-selector--cart .print-info .details {
  float: left;
  margin-bottom: 10px;
  width: 115px;
}

%print-selector--cart__print-quantitiy,
.print-selector--cart .print-info .quantity {
  font-family: $alternate-font;
}

%print-selector--cart__picks,
.print-selector--cart .choices {

  .placeholder {
    font-family: $alternate-font;
    font-size: $xlarge-font-size;
  }

  &.is-disabled {
    @extend %t--red;
  }
}

%print-selector--cart__selections,
.print-selector--cart .selections {
  @extend %clearfix;
  border-bottom: 1px solid $purple;
  margin-bottom: 15px;
  padding-bottom: 15px;
}

%print-selector--cart__prints,
.print-selector--cart .selected-images {
  float: left;
  margin: 0 5px 10px 5px;

  &.is-loading {
    @extend %icon--loader;
    outline: 1px solid $grey-medium;
    width: 70px;
    height: 105px;
  }
}

%print-selector--cart__print,
.print-selector--cart .image {
  margin: 0;
}

%print-selector--cart__btn-remove,
.print-selector--cart .remove {
  display: block;
  margin: 5px 0;
  text-align: center;
}

.print-selector--cart__form,
.print-selector--cart form {

  > div {
    @extend %clearfix;
  }
}

%print-selector--cart__btn-back,
.print-selector--cart .btn-action--secondary-back-grey {
  float: left;
}

%print-selector--cart__btn--next,
.print-selector--cart .btn-action--primary-forward-grey__container {
  float: right;
  margin: 0;
}

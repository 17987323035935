%g-list--reset__list {
  margin: 0;
  list-style-type: none;
}

%g-list--reset__list-item {

}

%g-list--reset,
.g-list--reset {
  @extend %g-list--reset__list;

  li {
    // @extend %g-list--reset__list-item;
  }
}

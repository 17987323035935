// Need to remove the top margin on the first child element.
%content-helper--remove-first-child-top-margin {

  > :first-child {
    margin-top: 0;
  }
}

// Remove the the last child element bottom margin.
%content-helper--remove-last-child-bottom-margin {

  > :last-child {
    margin-bottom: 0;
  }
}

// Remove margins from first and last child element.
// Adding generic class to apply to wysiwyg fields to allow for consitent spacing in the page layouts.
%content-helper--remove-both-child-margins,
.content-helper--remove-both-child-margins {
  @extend %content-helper--remove-first-child-top-margin;
  @extend %content-helper--remove-last-child-bottom-margin;
}
// scss-lint:disable QualifyingElement

input[type=text],
input[type=password],
select,
textarea {
  line-height: 18px;
  color: $grey-xdark;
  font-family: $alternate-font;
  font-size: 13px;
}

input[type=text],
input[type=password],
select {
  border: 1px solid $grey-dark;
  box-sizing: border-box;
  padding: 4px 3px;
  height: 30px;
}

input[type=submit],
button {
  @extend %btn-primary--purple;
  cursor: pointer;
  font-family: $body-font;

  &:disabled {
    background-color: $grey-medium;
    cursor: not-allowed;

    &:hover {
      text-decoration: none;
    }
  }
}

input[type=radio] {
  height: auto;
}

select,
textarea {
  box-sizing: border-box;
  width: 100%;
}

label {
  display: block;
  margin: 4px 0;
}

// Disabled
%input--is-disabled,
select[disabled],
select[disabled=disabled] {
  background-color: $grey-medium;
  color: $white;
  cursor: not-allowed;
}

%label--inline,
label.option {
  display: inline-block;
}

%form__container,
.form-item,
.form-actions {
  @extend %clearfix;
  margin: 5px 0;
}

%form__input--error,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid $red;
}

.form-item input[type="radio"].error {
  outline: 1px solid $red;
}

fieldset {
  margin: 20px 0;
  border-bottom: 1px solid $purple-xlight;
  padding-bottom: 20px;
}

legend {
  @extend %h2--xlarge-green;
  margin-bottom: 10px;
}

.form-item .description {
  clear: both;
  padding: 5px 0 0;
}

.form-item-commerce-shipping-shipping-details-school-agreement.form-item .description {
  clear: none;
  padding: 0;
}

// Search Registry
//////////////////
%search--registry__container,
.block-block-16 {
  margin: 10px 0;
  background-color: $grey-xlight;
  padding: 20px 10px;
  text-align: center;
}

%search--registry__link,
.block-block-16 a {
  @extend %t--xlarge;
  @extend %link--green;
  font-family: $title-font;
}

%search--registry__search-container,
.view-gift-registry .views-widget-filter-title {
  float: left;
}

%search-registry__submit-container,
.view-gift-registry .views-submit-button {
  float: left;
  margin: 27px 0 0 5px;
}

.view-gift-registry .views-exposed-widgets,
.view-gift-registry .view-content {
  @extend %clearfix;
}

// Shipping Pane
////////////////
%f-shipping-options,
.f-shipping-options {
  @extend %clearfix;
  background-color: $purple-xxlight;
  padding: 10px;
}

%f-shipping-options__directions,
.f-shipping-options .directions {
  margin-top: 35px;
  text-align: right;
}

%f-shipping-options__shipping,
.f-shipping-options .form-item-commerce-shipping-shipping-method {
  float: left;
  margin: 0 10px 0 0;
  width: 140px;
}

%f-shipping-options__shipping-details,
#shipping-details {
  float: right;
  width: 300px;
}

%f-shipping-options__school,
.f-shipping-options .form-item-commerce-shipping-shipping-details-schools {
  margin-top: 0;
}

%f-shipping-options__school-description,
.f-shipping-options .form-item-commerce-shipping-shipping-details-schools .description {
  margin: 5px 0 10px;
}

%f-shipping-options__agreement,
.f-shipping-options .form-item-commerce-shipping-shipping-details-school-agreement {
  border: 1px solid $grey-dark;
  padding: 2px;
}

%f-shipping-options__agreement-input,
.f-shipping-options input[type="checkbox"] {
  float: left;
  margin-right: 5px;
}

// Customer Profile Pane
////////////////////////
.customer_profile_billing label {
  display: inline-block;
  margin-right: 10px;
  text-align: right;
  width: 100px;
}

.customer_profile_billing input,
.customer_profile_billing select {
  display: inline-block;
  width: 200px;
}

.customer_profile_billing .field-name-field-shipping-instructions .help {
  background-color: $grey-xlight;
  margin: 5px 0;
  padding: 10px;
}

.customer_profile_billing .street-block,
.customer_profile_billing .locality-block,
.customer_profile_billing .field-name-field-phone,
.customer_profile_billing .field-name-field-email {
  margin-top: -5px;
}

// Coupon Pane
//////////////
.commerce_coupon .fieldset-wrapper {
  @extend %clearfix;
  position: relative;
}

.commerce_coupon .form-item-commerce-coupon-coupon-code {
  float: left;
  // margin: 0 0 0 250px;
}

.form-item-commerce-coupon-coupon-code input[type="text"] {
  width: 48px;
}

.commerce_coupon label {
  display: inline-block;
}

.commerce_coupon input[type=submit] {
  float: left;
  margin-top: 7px;
  margin-left: 12px;
}

// Slider
.slider-wrapper {
  float: right;
  width: 300px;
}

.slider__prefix {
  position: relative;
  top: 5px;
  margin-right: 10px;
}

.slider__suffix {
  position: relative;
  top: 5px;
  margin-left: 10px;
}

.slider__help {
  text-indent: 40px;
  font-weight: 600;
  font-size: 14px;

  a {
    color: #360b54;
  }
}

.page-print-shop-download .l-form__actions--left .form-type-checkbox {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.page-print-shop-download .l-form__actions--left .form-type-checkbox input {
  opacity: 0;
}

.page-print-shop-download .l-form__actions--left .form-type-checkbox label {
  position: absolute; 
  overflow: hidden; 
  clip: rect(0 0 0 0); 
  height: 1px; width: 1px; 
  margin: -1px; padding: 0; border: 0; 
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

.page-print-shop-download .l-form__actions--left .form-type-checkbox input:checked + .field-suffix .slider {
  background-color: #a38eeb;
}

.page-print-shop-download .l-form__actions--left .form-type-checkbox input:focus + .field-suffix .slider {
  box-shadow: 0 0 1px #a38eeb;
}

.page-print-shop-download .l-form__actions--left .form-type-checkbox input:checked + .field-suffix .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

input[type=submit].js-hide {
  display: none;
}

%c-shopping-cart--form,
.c-shopping-cart--form {
  @extend %c-shopping-cart;
}

%c-shopping-cart--form__header,
.c-shopping-cart--form {
  @extend %c-shopping-cart__header;
}

// table headers
%c-shopping-cart--form__th-unit-total,
.c-shopping-cart--form th:nth-child(6) {
  text-align: right;
}

// table cells
%c-shopping-cart--form__product-icon,
.c-shopping-cart--form td:nth-child(1) {
  border-right: 2px solid $grey-light;
  // padding: 5px 6px 5px 0;
  width: 30px;
}

%c-shopping-cart--form__product-title,
.c-shopping-cart--form td:nth-child(2) {
  width: 300px;
}

%c-shopping-cart--form__unit-price,
.c-shopping-cart--form td:nth-child(3) {
  width: 100px;
}

%c-shopping-cart--form__edit-quantity,
.c-shopping-cart--form td:nth-child(4) {
  width: 100px;
}

%c-shopping-cart--form__product-actions,
.c-shopping-cart--form td:nth-child(5) {
  width: 250px;
}

%c-shopping-cart--form__unit-total,
.c-shopping-cart--form td:nth-child(6) {
  text-align: right;
}

%c-shopping-cart--form__summary,
.c-shopping-cart--form .line-item-summary {
  margin: 10px 0 20px;
  text-align: right;

  .line-item-total {
    font-weight: $bold-font-weight;
  }
}

/**
 * Progress behavior.
 *
 * @see progress.js
 */
/* Bar */
.progress .bar {
  background-color: $white;
  border: 1px solid;
}
.progress .filled {
  background-color: $black;
  height: 1.5em;
  width: 5px;
}
.progress .percentage {
  float: right; /* LTR */
}
/* Throbber */
.ajax-progress {
  display: inline-block;
}
.ajax-progress .throbber {
  background: transparent url(/misc/throbber-active.gif) no-repeat 0px center;
  float: left; /* LTR */
  height: 15px;
  margin: 2px;
  width: 15px;
}
.ajax-progress .message {
  padding-left: 20px;
}
tr .ajax-progress .throbber {
  margin: 0 2px;
}
.ajax-progress-bar {
  width: 16em;
}

$max-width: 1040px;

.l-footer {
  background-color: $grey-light;
}

.l-footer__wrapper {
  @extend %clearfix;

  background-color: $grey-light;
  font-size: $xsmall-font-size;
  margin: 0 auto;
  padding: 12px 0;
  width: $max-width;
}

.l-footer__copyright {
  float: left;
  margin-right: 30px;
  margin-top: 6px;

  p {
    margin: 0;
  }
}

.l-footer__menu {
  float: left;
  margin-top: 6px;

  ul {
    display: block;
    list-style: none;
    margin: 0;
  }
}

.l-footer__social {
  float: right;
  margin-top: 7px;
}

.l-footer__credit {
  float: right;
  margin-left: 30px;
  padding-top: 3px
}

.m-share__icons-wrapper {
  @extend %clearfix;
}

%m-share__icon {
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  float: left;
  height: 18px;
  margin: 0 3px;
  width: 18px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.m-share__icon--twitter {
  @extend %m-share__icon;
  @extend %icon--twitter;
}

.m-share__icon--facebook {
  @extend %m-share__icon;
  @extend %icon--facebook;
}

.m-share__icon--linkedin {
  @extend %m-share__icon;
  @extend %icon--linkedin;
}

.m-share__icon--instagram {
  @extend %m-share__icon;
  @extend %icon--instagram;
}

%menu-credit__list,
.menu-credit__list {
  display: block;
  list-style: none;
  margin: 0;
}

%menu-credit__list-item,
.menu-credit__list-item {
  @extend %list--reset;
  display: inline-block;
}

%menu-credit__icon--core {
  background-repeat: no-repeat;
  display: block;
  height: 30px;
  overflow: hidden;
  text-indent: 100%;
}

%menu-credit__icon--mastercard,
.menu-credit__icon--mastercard {
  @extend %menu-credit__icon--core;
  @extend %icon--mastercard;
  margin-right: 5px;
  width: 51px;
}

%menu-credit__icon--visa,
.menu-credit__icon--visa {
  @extend %menu-credit__icon--core;
  @extend %icon--visa;
  width: 79px;
}

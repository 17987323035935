%c-shopping-cart--block,
.c-shopping-cart--block {
  @extend %c-shopping-cart;
  position: relative;
  padding: 20px 10px;
}

%c-shopping-cart--block__title,
.c-shopping-cart--block__title {
  @extend %h2--xlarge-green;
  @extend %icon--cart;
  margin: 0;
  background-position: right top;
  padding-bottom: 25px;

  &.empty {
    @extend %icon--cart;
  }
}

%c-shopping-cart--block__content,
.c-shopping-cart--block__content,
.c-shopping-cart--block--content {
  border-top: 1px solid $grey-light;

  .cart-empty & {
    border-top: 0;
  }
}

%c-shopping-cart--block__td,
.c-shopping-cart--block td {
  @extend %c-shopping-cart__td;
}

%c-shopping-cart--block__td--icon,
.c-shopping-cart--block td:nth-child(1) {
  padding: 5px 5px 5px 0;
  width: 30px;

  img {
    display: block;
  }
}

%c-shopping-cart--block__td--quantity,
.c-shopping-cart--block td:nth-child(2) {
  width: 25px;
}

%c-shopping-cart--block__td--title,
.c-shopping-cart--block td:nth-child(3) {
  width: 100px;
}

%c-shopping-cart--block__td--total,
.c-shopping-cart--block td:nth-child(4) {
  width: 70px;
  text-align: center;
}

%c-shopping-cart--block__td--actions,
.c-shopping-cart--block td:nth-child(5) {
  padding-right: 0;
  padding-left: 0;
  width: 25px;

  input {
    @extend %icon--remove;
    float: left;
    border: 0;
    background-color: transparent;
    background-position: center center;
    padding: 0;
    width: 25px;
    min-width: auto;
    height: 25px;
    overflow: hidden;
    text-indent: 100%;

    &:hover {
      @extend %icon--remove-hover;
      background-color: transparent;
    }
  }
}

%c-shopping-cart--block__line-item-total,
.c-shopping-cart--block .line-item-total {
  margin: 10px 0;
  text-align: right;
}

%c-shopping-cart--block__label,
.c-shopping-cart--block .line-item-total-label {
  font-weight: $bold-font-weight;
}

%c-shopping-cart--block__summary,
.c-shopping-cart--block .line-item-summary {
  margin-top: 10px;
  border-top: 1px solid $grey-light;
  padding-top: 10px;
}

%c-shopping-cart--block__total-quantity,
.c-shopping-cart--block .line-item-quantity {
  position: absolute;
  top: 45px;
}

%c-shopping-cart--block__message,
.c-shopping-cart--block .line-item-message {
  margin-bottom: 30px;
  text-align: right;
}

%c-shopping-cart__actions,
.c-shopping-cart--block .links {
  @extend %clearfix;
  @extend %g-list--reset__list;

  .first {
    float: right;
    margin-bottom: 15px;
  }

  .last {
    float: right;
  }
}




/**
 * Package Block
 *********/
.page-print-shop .product-info .button {
  width: 100%;
}

.page-print-shop .product-info .button a {
  background-color: $purple;
  padding: 0;
  margin: 0;
}

.page-print-shop .product {
  height: 140px;
}

.page-print-shop .product .proceed {
  padding: 0 0 0 30px;
}

.page-print-shop .product .image {
  height: 70px;
  margin: 0 0 10px;
}

.page-print-shop .custom-product .image {
  height: 100%;
}

.page-print-shop .product .title {
  margin: 0;
}

.print-info .quantity,
.print-info .size {
  float: left;
  line-height:24px;
  padding: 3px 0;
}

.print-info .size {
  width: 80px;
}

.print-info .quantity {
  background-color: $purple-light;
  color: $white;
  font-size: 20px;
  margin: 0 5px 0 0;
  text-align: center;
  width: 30px;
}

.page-print-checkout-add-individual-prints .view-commerce-cart-block .line-item-summary .links {
  display: none;
}

/**
 * Block-block-2 : Copyright
 */
.block-block-2 .content {
  color: #A09C9C;
  float: right;
  font-size: 12px;
}

/**
 * Shopping Cart Block
 **********************/

.block-commerce-cart .views-field-line-item-title .details,
.page-cart .views-field-line-item-title .details,
.page-checkout .views-field-line-item-title .details {
  margin: 0;
}

.block-commerce-cart .views-field-line-item-title .details li,
.page-cart .views-field-line-item-title .details li,
.page-checkout .views-field-line-item-title .details li {
  color: #78706A;
  font-size: 12px;
  line-height: 15px;
  margin: 0 0 0 9px;
}

/**
 * Featured Block
 ****************************************************/
.block-views-ca-block,
.block-block-3,
.block-block-4,
.block-block-5,
.block-block-6,
.block-block-7,
.block-block-8,
.block-block-9,
.block-block-10,
.block-block-11,
.block-block-12,
.block-views-gift-registry-header-block,
.block--product-header {
  background-color: #F2F2F3;
  padding: 10px;
  margin: 0 0 10px;
}

.block-block-3 .image,
.block-block-4 .image,
.block-block-5 .image,
.block-block-6 .image,
.block-block-7 .image,
.block-block-8 .image,
.block-block-9 .image,
.block-block-10 .image,
.block-block-11 .image,
.block-block-12 .image{
  background-color: #A09C9C;
  height: 220px;
  width: 450px;
}

/**
 * Buy Prints
 *
 */
.order-summary-prints {
  margin: 0 0 10px;
}

.order-summary-prints .grid_6 {
  background-color: #F2F2F3;
  padding: 10px 0;
}

.order-summary-prints .image {
  background-color: #CFCFCF;
  height: 100px;
  margin: 0 10px 5px;
  width: 210px;
}

.order-summary-prints .spacing {
  padding: 0 10px;
}

.order-summary-prints .button {
  width: 100%;
}

.block-block-16 h2 a {
  color: $purple;
}

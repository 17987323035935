/**
 * Main Menu
 ************/
.main-menu {
  // border-bottom: 1px solid $purple;
  // margin-top: 10px;
  // padding-bottom: 20px;
}

.main-menu ul {
  display: block;
  margin: 0;
  text-align: right;
}

.main-menu li {
  display: inline;
  display: inline-block;
  list-style-type: none;

  a {
    color: $white;
    font-weight: $body-font-weight;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
      color: $purple-xlight;
    }
  }

  &:not(:last-of-type) {
    a {
      background-image: linear-gradient(to bottom, $white, $white);
      background-position: right center;
      background-repeat: no-repeat;
      background-size: 1px 100%;
      margin-right: 6px;
      padding-right: 12px;
    }
  }
}

.main-menu .menu-1259,
.gift-registry .main-menu .menu-909 {
  display: none;
}

.gift-registry .main-menu .menu-1259 {
  display: inline;
}

/**
 * Product Menu
 * ******************************************************/
 %menu-product,
.block-menu-block-1 ul,
.block-menu-block-1 ul li.leaf,
.block-menu-block-1 ul li.expanded {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.block-menu-block-1 ul {
  background: $purple;
}

%menu-product__btn--closed,
.block-menu-block-1 li.closed a {
  @extend %icon--minus;
  background-position: right center;
}

.block-menu-block-1 li.open a {
  @extend %icon--plus;
  background-position: right center;
}

.block-menu-block-1 li.open li a,
.block-menu-block-1 li.closed li a {
  background: transparent;
}

.js .block-menu-block-1 li .menu {
  display: none;
}

.block-menu-block-1 li.closed .menu {
  display: none;
}

.block-menu-block-1 li.open .menu {
  display: block;
}

.block-menu-block-1 li a {
  border-bottom: 1px solid $white;
  color: $white;
  display: block;
  font-family: $title-font;
  font-size: 16px;
  font-weight: $body-font-weight;
  line-height: 30px;
  padding: 0 15px;
}

.block-menu-block-1 .last a{
  border-bottom: 0;
}

.block-menu-block-1 ul ul{
  background-color: #F2F2F3;
  padding: 5px 0 15px;
}

.block-menu-block-1 li li a{
  border-bottom: 0;
  color: #78706A;
  font-family: ff-meta-web-pro,sans-serif;
  font-size: 14px;
  line-height: 20px;
  padding: 0 15px;
}

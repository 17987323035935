// SHOPPING CART SUMMARY (CHECKOUT PAGE)
////////////////////////////////////////
%shopping-cart--summary__table--products,
.view-commerce-cart-summary .view-content > table {
  margin: 0;
}

%shopping-cart--summary__th--products,
.view-commerce-cart-summary .view-content th {
  border-bottom: 1px solid $purple-xlight;
  padding: 5px 15px 5px 10px;
}

%shopping-cart--summary__td--products,
.view-commerce-cart-summary .view-content td {
  background-color: $grey-light;
  padding: 5px 15px 5px 10px;
  vertical-align: middle;
}

%shopping-cart--summary__footer,
.view-commerce-cart-summary .view-footer {
  @extend %icon--cart;
  background-color: $grey-xlight;
  background-position: 15px 15px;
  padding: 15px;
}

%shopping-cart--summary__items,
.view-commerce-cart-summary .field-items {
  @extend %clearfix;
}

%shopping-cart--summary__component,
.view-commerce-cart-summary .commerce-price-formatted-components td {
  padding: 0;
}

%shopping-cart--summary__actions,
.view-commerce-cart-summary .actions--cart {
  @extend %clearfix;
  border-top: 1px solid $white;
  padding: 10px 0 0;

  &.last {
    margin-right: 0;
  }
}

%shopping-cart--summary__letter,
.view-commerce-cart-summary .letter {
  @extend %c-shopping-cart__letter;
}

// SHOPPING CART FORM (VIEW MY CART)
////////////////////////////////////
%shopping-cart--form,
.view-commerce-cart-form {
  padding: 30px 0;
}

%shopping-cart--form__actions,
.commerce-line-item-views-form .commerce-line-item-actions {
  @extend %clearfix;
}

%shopping-cart--form__actions-cart,
.view-commerce-cart-form .actions--cart {
  float: right;

  input {
    margin-right: 30px;

    &.btn--update-cart {
      background-color: $purple-light;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

%shopping-cart--form__letter,
.view-commerce-cart-form .letter {
  @extend %c-shopping-cart__letter;
}

.view-commerce-cart-form .views-field-edit-delete .btn-action {
  display: inline-block;
  min-width: 50px;
}

/**
 * Default
 *********/
body {
  background: $white;
  margin: 0;
}

.right {
  float: right;
}

.hidden {
  display: none;
}

.loading {
  @extend %icon--loader;
}

/**
 * Buttons
 **********/
.button a,
.btn-action {
  background-color: $purple;
  border: 0;
  color: $white;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding: 0 5px;
  text-align: center;
}

.button {
  display: block;
  float: left;
  margin: 0;
}

.button.normal {
  background-color: $purple-light;
}

.normal a {
  color: $white;
  height: 30px;
  line-height: 30px;
}

.btn-action.secondary,
.button.green a {
  background-color: $purple-light;
  color: $white;
  height: 30px;
  line-height: 30px;
}

.button.darkgreen a {
  background-color: $purple;
  color: $white;
  height: 30px;
  line-height: 30px;
}

.button.green a:hover,
.button.darkgreen a:hover {
  text-decoration: underline;
}

.button.proceed {
  background: transparent url('../images/bg_green_arrow_right_dark.png') no-repeat right 0;
}

.button.proceed a,
.button.proceed input {
  background-color: $purple;
  border: 0;
  color: $white;
  height: 30px;
  line-height: 30px;
  margin: 0 15px 0 0;
}

.btn-action.forward {
  position: relative;
  padding-right: 20px;
}

.btn-action.forward.grey:after {
  background-color: $grey-xlight;
}

.btn-action.forward:after {
  background: transparent url('../images/bg_green_arrow_right_dark.png') no-repeat 0 0;
  content: "";
  display: block;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  width: 15px;
}

.button.return {
  background: transparent url('../images/bg_green_arrow_left.png') no-repeat 0 0;
}

.button.return a,
.button.return input {
  background-color: $purple-light;
  color: $white;
  height: 30px;
  line-height: 30px;
  margin: 0 0 0 15px;
}

.button.add input {
  border: 0;
  background-color: $purple;
  color: $white;
  height: 30px;
}

.button.download {
  float: none;
  margin-top: 10px;
}

/*****
 * Tables
 * *******************************************/
.view-commerce-cart-form th,
.page-user-orders th,
.c-shopping-cart--summary th {
  border-bottom: 2px solid $purple;
  border-top: 2px solid $purple;
  font-weight: $bold-font-weight;
  padding: 12px;

  border-right: 2px solid $grey-light;

  &:last-of-type {
    border-right: 0;
  }

}

.view-commerce-cart-form tr,
.view-commerce-cart-summary tr {

  border-bottom: 1px solid $purple;

  td {
    border-right: 2px solid $grey-light;
    padding: 18px 12px;

    &:last-of-type {
      border-right: 0;
    }
  }

  &.component-type-commerce-price-formatted-amount {
    border: 0;

    td {
      border: 0;
    }
  }
}


#views-form-commerce-cart-form-default {
  tbody {
    tr {
      &:first-of-type {
        display: none;
      }
    }
  }
}

.view-commerce-cart-form td,
.page-user-orders th {
  vertical-align: middle;
}

.view-commerce-cart-form th.views-field-line-item-title {
  width: 250px;
}

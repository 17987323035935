// System message
%message-system--core {
  @extend %t-large-xdark-grey;
  border: 1px solid;
  margin: 10px 0;
  padding: 10px;

  li {
    @extend %list--reset;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

%message-system--error,
.messages.error {
  @extend %message-system--core;
  background-color: $red-light;
  border-color: $red;
}

%message-system--status,
.messages.status {
  @extend %message-system--core;
  background-color: $purple-xxlight;
  border-color: $purple;
}

%message-system--warning,
.messages.warning {
  @extend %message-system--core;
  background-color: $grey-light;
  border-color: $grey-dark;
}

// Message Info
.message--info {
  @extend %content-helper--remove-both-child-margins;
  @extend %icon--info;
  margin: 10px 0;
  min-height: 40px;
  padding-left: 40px;
}

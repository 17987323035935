%c-shopping-cart--summary,
.c-shopping-cart--summary {
  @extend %c-shopping-cart;

  padding-bottom: 18px;
}

%c-shopping-cart--summary__basket,
.c-shopping-cart--summary .c-shopping-cart__basket {
  // padding: 15px;
}

%c-shopping-cart--summary__header,
.c-shopping-cart--summary {
  @extend %c-shopping-cart__header;

  table:nth-of-type(1) {

    thead {
      border-bottom: 1px solid $purple-light;
    }
  }
}

%c-shopping-cart--summary__table--categories,
.c-shopping-cart--summary .c-shopping-cart__basket table {
  margin-bottom: 10px;
  border-bottom: 1px solid $grey-medium;

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: 0;
  }
}

// table headers
%c-shopping-cart--summary__th--unit-total,,
.c-shopping-cart--summary .c-shopping-cart__basket td:nth-child(6) {
  text-align: right;
}

%c-shopping-cart--summary__td,
.c-shopping-cart--summary td {
  vertical-align: middle;
}

// table cells
%c-shopping-cart--summary__product-icon,
.c-shopping-cart--summary .c-shopping-cart__basket td:nth-child(1) {
  padding: 5px 5px 5px 0;
  width: 30px;
}

%c-shopping-cart--summary__product-title,
.c-shopping-cart--summary .c-shopping-cart__basket td:nth-child(2) {
  width: 150px;
}

%c-shopping-cart--summary__unit-price,
.c-shopping-cart--summary .c-shopping-cart__basket td:nth-child(3) {
  width: 75px;
}

%c-shopping-cart--summary__quantity,
.c-shopping-cart--summary .c-shopping-cart__basket td:nth-child(4) {
  width: 75px;
  text-align: center;
}

%c-shopping-cart--summary__unit-total,
.c-shopping-cart--summary .c-shopping-cart__basket td:nth-child(5) {
  text-align: right;
}

%c-shopping-cart--summary__footer-item,
.c-shopping-cart--summary .view-footer .field-item {
  @extend %clearfix;
  margin-bottom: 10px;
  padding-bottom: 10px;
}

/**
 * Default
 */
body {
  @extend %t--xdark-grey;
  line-height: $base-line-height;
  font-family: $body-font;
  font-size: $base-font-size;
  font-weight: $body-font-weight;
}

p {
  margin: 10px 0;
}

ul {
  margin: 10px 0;
  list-style-type: disc;
}

li {
  list-style-position: inside;
}

em {
  font-style: italic;
}

strong {
  font-weight: $bold-font-weight;
}

/**
 * Headings
 */
h1 {
  border-bottom: 2px solid $purple;
  color: $purple;
  font-family: $title-font;
  font-size: $h1-font-size;
  font-weight: $title-font-weight;
  line-height: 37px;
  margin-bottom: 30px;
  padding: 20px 0;
  text-transform: uppercase;
}

%t--h2,
h2 {
  color: $grey-xdark;
  font-size: $h2-font-size;
  font-weight: $title-font-weight;
  line-height: 24px;
  margin-bottom: 15px;
  text-transform: capitalize;
}

h3 {
  @extend %t--large;
  margin-bottom: 10px;
}

h5 {
  text-decoration: underline;
  color: $black;
}

/**
 * Type size variations.
 */
%t--xxsmall,
.t--xxsmall {
  line-height: 12px;
  font-size: $xxsmall-font-size;
}

%t--xsmall,
.t--xsmall {
  line-height: 16px;
  font-size: $xsmall-font-size;
}

%t--small,
.t--small {
  line-height: 16px;
  font-size: $small-font-size;
}

%t--medium,
.t--medium {
  line-height: $base-line-height;
  font-size: $base-font-size;
}

%t--large,
.t--large {
  line-height: 20px;
  font-size: $large-font-size;
}

%t--xlarge,
.t--xlarge {
  line-height: 24px;
  font-size: $xlarge-font-size;
}

%t--xxlarge,
.t--xxlarge {
  line-height: 36px;
  font-size: $xxlarge-font-size;
}

%t--huge,
.t--huge {
  line-height: 46px;
  font-size: $huge-font-size;
}

%h2--xlarge-green {
  @extend %t--xlarge;
  @extend %t--green;
  font-family: $title-font;
}

%h2--xxlarge-green {
  @extend %t--xxlarge;
  @extend %t--green;
  font-family: $title-font;
}

%h3--xlarge {
  @extend %t--xlarge;
  font-family: $title-font;
}

%t-large-xdark-grey {
  @extend %t--large;
  @extend %t--xdark-grey;
}

// Spacing variations.
%t--leading-none,
.t--leading-none {
  margin-top: 0;
}

%t--leading-xlarge,
.t--leading-xlarge {
  margin-top: 20px;
}

%t--trailer-none,
.t--trailer-none {
  margin-bottom: 0;
}

%t--trailer-xlarge {
  margin-bottom: 20px;
}

%t--spacing-none,
.t--spacing-none {
  @extend %t--leading-none;
  @extend %t--trailer-none;
}

%t--spacing-xlarge,
.t--spacing-xlarge {
  @extend %t--leading-xlarge;
  @extend %t--trailer-xlarge;
}

// Other variations.
%t--red,
.t--red {
  color: $red;
}

%t--red-italic {
  @extend %t--red;
  @extend %t--italic;
}

%t--green,
.t--green {
  color: $purple;
}

%t--xdark-grey {
  color: $grey-xdark;
}

%t--bold,
.t--bold {
  font-weight: $bold-font-weight;
}

%t--italic {
  font-style: italic;
}

%t--align-center,
.t--align-center {
  text-align: center;
}

%t--uppercase,
.t--uppercare {
  text-transform: uppercase;
}

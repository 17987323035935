.l-flex--3col {
  box-sizing: border-box;
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 -5px;

  > * {
    box-sizing: inherit;
    margin: 0 5px 5px 5px;
    width: calc(33.333333% - 10px);
  }
}

%icon--info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAiCAYAAADRcLDBAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA2ZpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpGQjdGMTE3NDA3MjA2ODExODA4M0VCODNDNjJCRDdDMSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDowNjQ3ODNCQkM1MDQxMUUwOUFCQkI1Rjc4NzM4MUIxRiIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDowNjQ3ODNCQUM1MDQxMUUwOUFCQkI1Rjc4NzM4MUIxRiIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ1M1IE1hY2ludG9zaCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjY5NUJFNEM4MzAyMDY4MTE4OEM2RjNCODc0QTk0ODQ2IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkZCN0YxMTc0MDcyMDY4MTE4MDgzRUI4M0M2MkJEN0MxIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+hqzQBAAAASxJREFUeNpi7OzsZEADWkBsBsRsDLQBN4H4FBB/hwmwIElGAfEEIBZloA/oA+IakGOYoAKNQLyUjg4AgSIg3gXEzCBHqANxNcPAABsgzmGCRgMzw8CBZJAjFBkGFqgzDXAogAAbE8MgABQ7Ql5eHowpASyUaPb392dQV1cHsy9evMiwc+dO+oaEhIQE3AEgoK+vzyAgIEBfR/z48YMoMZo64sOHD+DgB1kMwjA23dMEKB2AMKWAIkeUlZXB2Q8fPmRYuXLlwGTRQVFOjDpi1BGjjhj2jqCoxOzq6hqNjuHpiL8D7IZfIEc8GmBH3AE5YuUAh8ZckCMuA3HHADngOBBPhCVMUBc9Gog/0tEBE4HYDRQLyIXVMiBeA8TGQKxLQ8tBafACEL+ACQAEGAB150epRvzlmAAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
}

%icon--remove {
  background-image: url('./img/ico-remove.png');
  background-repeat: no-repeat;
}

%icon--remove-hover {
  background-image: url('./img/ico-remove-hover.png');
  background-repeat: no-repeat;
}

%icon--arrow-right-purple {
  background-image: url('./img/ico-arrow-right-purple.png');
  background-repeat: no-repeat;
}

%icon--arrow-left-light-purple {
  background-image: url('./img/ico-arrow-right-purple-light.png');
  background-repeat: no-repeat;
}

%icon--arrow-right-xlight-purple {
  background-image: url('./img/ico-arrow-right-purple-xlight.png');
  background-repeat: no-repeat;
}

%icon--cart {
  background-image: url('./img/ico-cart.png');
  background-repeat: no-repeat;
}

%icon--mastercard {
  background-image: url('./img/ico-mastercard.png');
  background-repeat: no-repeat;
}

%icon--visa {
  background-image: url('./img/ico-visa.png');
  background-repeat: no-repeat;
}

%icon--plus {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACxJREFUeNpi/P//PwMOAJNgxCbJxEAmGAkaWZBCj1DoUs9GxtF4pLJGgAADACKDBxzACfnxAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
}

%icon--minus {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAACRJREFUeNpi/P//PwM5gImBTDASNLIA8X+628g4Gh1U1ggQYABD3QQdrMZRjAAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
}

%icon--loader {
  background-image: url('../images/loader.gif');
  background-position: center center;
  background-repeat: no-repeat;
}

%icon--update {
  background-image: url('./img/ico-update.png');

  &:hover,
  &:active,
  &:focus {
    background-image: url('./img/ico-update-hover.png');
  }
}

%icon--arrow-right {
  background-image: url('./img/ico-arrow-right.png');

  &:hover,
  &:active,
  &:focus {
    background-image: url('./img/ico-arrow-right-hover.png');
  }
}

%icon--arrow-left {
  background-image: url('./img/ico-arrow-left.png');

  &:hover,
  &:active,
  &:focus {
    background-image: url('./img/ico-arrow-left-hover.png');
  }
}

%icon--twitter {
  background-image: url('./img/ico-twitter.png');

  &:hover,
  &:active,
  &:focus {
    background-image: url('./img/ico-twitter-hover.png');
  }
}

%icon--facebook {
  background-image: url('./img/ico-facebook.png');

  &:hover,
  &:active,
  &:focus {
    background-image: url('./img/ico-facebook-hover.png');
  }
}

%icon--linkedin {
  background-image: url('./img/ico-linkedin.png');

  &:hover,
  &:active,
  &:focus {
    background-image: url('./img/ico-linkedin-hover.png');
  }
}

%icon--instagram {
  background-image: url('./img/ico-instagram.png');

  &:hover,
  &:active,
  &:focus {
    background-image: url('./img/ico-instagram-hover.png');
  }
}

%c-shopping-cart {
  table {
    margin: 0;
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    border-spacing: 0;
  }
}

%c-shopping-cart__header {

  table:nth-of-type(1) thead {
    display: table-header-group;
  }

  thead {
    display: none;
  }
}

%c-shopping-cart__category,
.c-shopping-cart__category {
  @extend %t--large;
  margin: 5px 0;
  text-align: left;
  font-weight: $bold-font-weight;
}

%c-shopping-cart__td {
  vertical-align: middle;
}

%c-shopping-cart__letter--core {
  margin: 0;
  background-color: $purple-xlight;
  padding: 4px 0 2px;
  vertical-align: bottom;
  text-align: center;
  text-transform: uppercase;
  box-sizing: border-box;
  font-family: $title-font;
}

// Package icon.
%c-shopping-cart__letter,
.c-shopping-cart__letter {
  @extend %c-shopping-cart__letter--core;
  @extend %h2--xlarge-green;
  width: 30px;
  height: 30px;

  .two {
    font-size: $large-font-size;
  }
}

%c-shopping-cart__package-details,
.c-shopping-cart__details {
  @extend %g-list--reset__list;
}

%c-shopping-cart__package-details,
.c-shopping-cart__package-details {

  &[data-shopping-cart-state="hidden"] {
    display: none;
  }
}

%menu-footer__list,
.menu-footer ul {
  @extend %clearfix;
}

%menu-footer__list-item,
.menu-footer li {
  @extend %list--reset;
  border-right: 1px solid $grey-dark;
  float: left;
  padding: 0 10px;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    border: 0;
  }
}

%menu-footer__button,
.menu-footer a {
  color: $grey-dark;
}

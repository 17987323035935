%l-form__actions,
.l-form__actions {
  margin: 20px 0;
}

%l-form__actions--right,
.l-form__actions--right {
  @extend %l-form__actions;
  text-align: right;

  &.add-to-cart.bottom {
    border-top: 1px solid $red;
    padding-top: 25px;
    margin-top: 25px;
  }

  &.add-to-cart.top {
    margin-bottom: 0;
  }
}

.l-form__actions--left {
  @extend %l-form__actions;
}

%l-form__container--category,
.l-form__container--category {
  margin-top: 10px;
  border-top: 1px solid $red;
  padding-top: 10px;
}

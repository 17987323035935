// .l-header__logo {
//   float: left;
//   width: 790px;
// }

// .l-header__certificate {
//   float: left;
//   width: 150px;
// }

$max-width: 1040px;

.l-header__top {
  background-color: $purple-dark;
}

.l-header__top-wrapper {
  @extend %clearfix;

  background-color: $purple-dark;
  margin: 0 auto;
  min-height: 15px;
  padding: 12px 0;
  width: $max-width;
}

.l-header__bottom-wrapper {
  @extend %clearfix;

  border-bottom: 2px solid $purple;
  margin: 0 auto;
  padding: 24px 0;
  width: $max-width;
}


.l-header__logo {
  float: left;
  width: 220px;

  img {
    height: auto;
    width: 100%;
  }
}

.l-header__tagline {
  float: right;
  font-size: 26px;
  font-weight: $thin-font-weight;
  margin-top: 22px;
}

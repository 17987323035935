
#colorbox,
#cboxOverlay,
#cboxWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  overflow: hidden;
}

#cboxOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

#cboxMiddleLeft,
#cboxBottomLeft {
  clear: left;
}

#cboxContent {
  position: relative;
}

#cboxLoadedContent {
  overflow-y: auto !important;
  overflow-x: hidden !important;
  // padding-right: 30px;
}

#cboxLoadingOverlay,
#cboxLoadingGraphic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#cboxPrevious,
#cboxNext,
#cboxClose,
#cboxSlideshow {
  cursor: pointer;
}

.cboxPhoto {
  float: left;
  margin: auto;
  border: 0;
  display: block;
}

.cboxIframe {
  width: 100%;
  height: 100%;
  display: block;
  border: 0;
}

#cboxOverlay {
  background: $black;
}

#colorBox {}

#cboxWrapper {
  background: $white;
  border-radius: 5px;
}

#cboxTopLeft {
  width: 15px;
  height: 15px;
}

#cboxTopCenter {
  height: 15px;
}

#cboxTopRight {
  width: 15px;
  height: 15px;
}

#cboxBottomLeft {
  width: 15px;
  height: 10px;
}

#cboxBottomCenter {
  height: 10px;
}

#cboxBottomRight {
  width: 15px;
  height: 10px;
}

#cboxMiddleLeft {
  width: 15px;
}

#cboxMiddleRight {
  width: 15px;
}

#cboxContent {
  background: $white;
  overflow: hidden;
  font: 12px "Lucida Grande", Verdana, Arial, sans-serif;
}

#cboxError {
  padding: 50px;
  border: 1px solid #ccc;
}

#cboxLoadedContent {
  margin-bottom: 35px;
}

#cboxTitle {
  position: absolute;
  background: rgba($white, 0.7);
  bottom: 28px;
  left: 0;
  color: #535353;
  width: 100%;
  padding: 4px;
  margin: 0;
}

#cboxCurrent {
  position: absolute;
  bottom: 4px;
  left: 60px;
  color: #949494;
}

.cboxSlideshow_on #cboxSlideshow {
  position: absolute;
  bottom: 0px;
  right: 30px;
  background: url(../images/controls.png) -75px -50px no-repeat;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
}

.cboxSlideshow_on #cboxSlideshow.hover {
  background-position: -101px -50px;
}

.cboxSlideshow_off #cboxSlideshow {
  position: absolute;
  bottom: 0px;
  right: 30px;
  background: url(../images/controls.png) -49px -50px no-repeat;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
}

.cboxSlideshow_off #cboxSlideshow.hover {
  background-position: -25px -50px;
}

#cboxPrevious {
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(../images/controls.png) -75px 0px no-repeat;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
}

#cboxPrevious.hover {
  background-position: -75px -25px;
}

#cboxNext {
  position: absolute;
  bottom: 0;
  left: 27px;
  background: url(../images/controls.png) -50px 0px no-repeat;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
}

#cboxNext.hover {
  background-position: -50px -25px;
}

#cboxLoadingOverlay {
  background: $white;
}

#cboxLoadingGraphic {
  @extend %icon--loader;
}

#cboxClose {
  position: absolute;
  bottom: 0;
  right: 0;
  background: url(../images/controls.png) -25px 0px no-repeat;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
}

#cboxClose.hover {
  background-position: -25px -25px;
}

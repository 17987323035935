%visually-hidden,
.element-invisible {
  border: 0;
  clip: rect(0 0 0 0);
  position: absolute;
  overflow: hidden;
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;

  &.focusable:active,
  &.focusable:focus {
    clip: auto;
    position: static;
    margin: 0;
    width: auto;
    height: auto;
    overflow: visible;
  }
}

%hidden,
.hidden {
  display: none;
}